// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {

  --ion-background-color: var(--ion-color-medium);
  --ion-text-color: #000000;
  --ion-text-color-rgb: 0,0,0;

  --ion-font-family: 'Nunito Sans', courier;  // was sans-serif instead of courier
  --chea-green: #9dcaab;
  --chea-purple: #c94b97;
  --chea-hotpink: #E0098A;
  --chea-blue: #0390b9;
  --chea-midnight: #000124;
  --chea-sea-blue: #0190B9;
  --chea-sea-foam: #6DAF8A;
  --chea-dark-blue: #010348;
  --chea-vellum: #F8F6EA;
  --chea-dusty-rose: #EC9EC0;
  --chea-raspberry: #A91B60;
  --chea-lipstick: #f75077;
  --chea-orange: #f3b49b;
  --chea-lightgray: #f8f8f8;
  --chea-grayfill: #dedee0;
  --chea-tangerine: #FF6F57;
  --gradient-orange: #e7bf7e;

  --ion-color-primary: #000000;
  --ion-color-primary-rgb: 0,0,0;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #7a6f6f;
  --ion-color-primary-tint: #c4acac;

  --ion-color-secondary: #c94b97;
  --ion-color-secondary-rgb: 201,75,151;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #b14285;
  --ion-color-secondary-tint: #ce5da1;

  --ion-color-tertiary: #0390b9;
  --ion-color-tertiary-rgb: 3,144,185;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #037fa3;
  --ion-color-tertiary-tint: #1c9bc0;

  --ion-color-success: #5ea842;
  --ion-color-success-rgb: 94,168,66;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #53943a;
  --ion-color-success-tint: #6eb155;

  --ion-color-warning: #ffce00;
  --ion-color-warning-rgb: 255,206,0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255,255,255;
  --ion-color-warning-shade: #e0b500;
  --ion-color-warning-tint: #ffd31a;

  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245,61,61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34,34,34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #f0f0f0;
  --ion-color-medium-rgb: 240,240,240;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #d3d3d3;
  --ion-color-medium-tint: #f2f2f2;

  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 255,255,255;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d7d7;
  --ion-color-light-tint: #f5f5f5;

  --ion-color-lipstick: #f75077;
  --ion-color-lipstick-rgb: 247,80,119;
  --ion-color-lipstick-contrast: #ffffff;
  --ion-color-lipstick-contrast-rgb: 0,0,0;
  --ion-color-lipstick-shade: #d94669;
  --ion-color-lipstick-tint: #f86285;    
}

.ion-color-lipstick {
  --ion-color-base: var(--ion-color-lipstick);
  --ion-color-base-rgb: var(--ion-color-lipstick-rgb);
  --ion-color-contrast: var(--ion-color-lipstick-contrast);
  --ion-color-contrast-rgb: var(--ion-color-lipstick-contrast-rgb);
  --ion-color-shade: var(--ion-color-lipstick-shade);
  --ion-color-tint: var(--ion-color-lipstick-tint);
}

// Shared Variables
// --------------------------------------------------
// To customize the look and feel of this app, you can override
// the CSS variables found in Ionic's source files.
// To view all the possible Ionic variables, see:
// https://beta.ionicframework.com/docs/theming/css-variables#ionic-variables

:root {
  // TODO
  // $headings-font-weight: 300;
  --ion-headings-font-weight: 300;

  --ion-color-angular: #ac282b;
  --ion-color-communication: #8e8d93;
  --ion-color-tooling: #fe4c52;
  --ion-color-services: #fd8b2d;
  --ion-color-design: #fed035;
  --ion-color-workshop: #69bb7b;
  --ion-color-food: #3bc7c4;
  --ion-color-documentation: #b16be3;
  --ion-color-navigation: #6600cc;
}

// App iOS Variables
// --------------------------------------------------
// iOS only CSS variables can go here

// App Material Design Variables
// --------------------------------------------------
// Material Design only CSS variables can go here

.md {
  // Use the primary color as the background for the toolbar
  --ion-toolbar-background: var(--ion-color-primary);

  // Change the color of the toolbar components
  --ion-toolbar-color: #fff;

  // Change the color of the activated toolbar components
  --ion-toolbar-color-activated: #fff;

  // Change the color of the unchecked segment buttons
  --ion-toolbar-color-unchecked: rgba(255, 255, 255, .6);

  // Change the color of the checked segment button
  --ion-toolbar-color-checked: #fff;
}

// App Theme
// --------------------------------------------------
// Ionic apps can have different themes applied, which can
// then be future customized. This import comes last
// so that the above variables are used and Ionic's
// default are overridden.

// @import "ionic.theme.default";

// Fonts
// --------------------------------------------------
// Roboto font is used by default for Material Design. Noto sans
// is used by default for Windows.

// @import "roboto";


// @media (prefers-color-scheme: dark) {
//   :root {
//     --ion-border-color: #2a2a2a;
//     --ion-background-color: var(--ion-color-dark);
//     --ion-background-color-rgb: var(--ion-color-dark-rgb);
//     --ion-text-color: #ffffff;
//     --ion-text-color-rgb: 255, 255, 255;

//     --ion-color-step-50: #232323;
//     --ion-color-step-100: #2e2e2e;
//     --ion-color-step-150: #3a3a3a;
//     --ion-color-step-200: #454545;
//     --ion-color-step-250: #515151;
//     --ion-color-step-300: #5d5d5d;
//     --ion-color-step-350: #686868;
//     --ion-color-step-400: #747474;
//     --ion-color-step-450: #7f7f7f;
//     --ion-color-step-500: #8b8b8b;
//     --ion-color-step-550: #979797;
//     --ion-color-step-600: #a2a2a2;
//     --ion-color-step-650: #aeaeae;
//     --ion-color-step-700: #b9b9b9;
//     --ion-color-step-750: #c5c5c5;
//     --ion-color-step-800: #d1d1d1;
//     --ion-color-step-850: #dcdcdc;
//     --ion-color-step-900: #e8e8e8;
//     --ion-color-step-950: #f3f3f3;
//   }

//   .md {
//     // Use the primary color as the background for the toolbar
//     --ion-toolbar-background: var(--ion-color-dark);

//     // Change the color of the toolbar components
//     --ion-toolbar-color: #fff;

//     // Change the color of the activated toolbar components
//     --ion-toolbar-color-activated: #fff;

//     // Change the color of the unchecked segment buttons
//     --ion-toolbar-color-unchecked: rgba(255, 255, 255, .6);

//     // Change the color of the checked segment button
//     --ion-toolbar-color-checked: #fff;
//   }
// }

.sc-ion-searchbar-ios-h {
  padding-left: unset;
  padding-right: unset;
  -webkit-padding-start: 0px;
  padding-inline-start: 0px;
  -webkit-padding-end: 0px;
  padding-inline-end: 0px;
}

// Dark Mode defined below -- uncomment if we need to support it
// See https://developer.apple.com/documentation/xcode/supporting_dark_mode_in_your_interface/choosing_a_specific_interface_style_for_your_ios_app
// /*
//  * Dark Colors
//  * -------------------------------------------
//  */
//  @media (prefers-color-scheme: dark) {
//   :root {
//   --ion-color-primary: #428cff;
//   --ion-color-primary-rgb: 66,140,255;
//   --ion-color-primary-contrast: #ffffff;
//   --ion-color-primary-contrast-rgb: 255,255,255;
//   --ion-color-primary-shade: #3a7be0;
//   --ion-color-primary-tint: #5598ff;

//   --ion-color-secondary: #50c8ff;
//   --ion-color-secondary-rgb: 80,200,255;
//   --ion-color-secondary-contrast: #ffffff;
//   --ion-color-secondary-contrast-rgb: 255,255,255;
//   --ion-color-secondary-shade: #46b0e0;
//   --ion-color-secondary-tint: #62ceff;

//   --ion-color-tertiary: #6a64ff;
//   --ion-color-tertiary-rgb: 106,100,255;
//   --ion-color-tertiary-contrast: #ffffff;
//   --ion-color-tertiary-contrast-rgb: 255,255,255;
//   --ion-color-tertiary-shade: #5d58e0;
//   --ion-color-tertiary-tint: #7974ff;

//   --ion-color-success: #2fdf75;
//   --ion-color-success-rgb: 47,223,117;
//   --ion-color-success-contrast: #000000;
//   --ion-color-success-contrast-rgb: 0,0,0;
//   --ion-color-success-shade: #29c467;
//   --ion-color-success-tint: #44e283;

//   --ion-color-warning: #ffd534;
//   --ion-color-warning-rgb: 255,213,52;
//   --ion-color-warning-contrast: #000000;
//   --ion-color-warning-contrast-rgb: 0,0,0;
//   --ion-color-warning-shade: #e0bb2e;
//   --ion-color-warning-tint: #ffd948;

//   --ion-color-danger: #ff4961;
//   --ion-color-danger-rgb: 255,73,97;
//   --ion-color-danger-contrast: #ffffff;
//   --ion-color-danger-contrast-rgb: 255,255,255;
//   --ion-color-danger-shade: #e04055;
//   --ion-color-danger-tint: #ff5b71;

//   --ion-color-dark: #f4f5f8;
//   --ion-color-dark-rgb: 244,245,248;
//   --ion-color-dark-contrast: #000000;
//   --ion-color-dark-contrast-rgb: 0,0,0;
//   --ion-color-dark-shade: #d7d8da;
//   --ion-color-dark-tint: #f5f6f9;

//   --ion-color-medium: #989aa2;
//   --ion-color-medium-rgb: 152,154,162;
//   --ion-color-medium-contrast: #000000;
//   --ion-color-medium-contrast-rgb: 0,0,0;
//   --ion-color-medium-shade: #86888f;
//   --ion-color-medium-tint: #a2a4ab;

//   --ion-color-light: #222428;
//   --ion-color-light-rgb: 34,36,40;
//   --ion-color-light-contrast: #ffffff;
//   --ion-color-light-contrast-rgb: 255,255,255;
//   --ion-color-light-shade: #1e2023;
//   --ion-color-light-tint: #383a3e;
// }

// /*
//  * iOS Dark Theme
//  * -------------------------------------------
//  */

// .ios {
//   --ion-background-color: var(--ion-color-medium-shade);
//   // --ion-background-color: #000000;
//   --ion-background-color-rgb: 0,0,0;

//   --ion-text-color: #ffffff;
//   --ion-text-color-rgb: 255,255,255;

//   --ion-color-step-50: #0d0d0d;
//   --ion-color-step-100: #1a1a1a;
//   --ion-color-step-150: #262626;
//   --ion-color-step-200: #333333;
//   --ion-color-step-250: #404040;
//   --ion-color-step-300: #4d4d4d;
//   --ion-color-step-350: #595959;
//   --ion-color-step-400: #666666;
//   --ion-color-step-450: #737373;
//   --ion-color-step-500: #808080;
//   --ion-color-step-550: #8c8c8c;
//   --ion-color-step-600: #999999;
//   --ion-color-step-650: #a6a6a6;
//   --ion-color-step-700: #b3b3b3;
//   --ion-color-step-750: #bfbfbf;
//   --ion-color-step-800: #cccccc;
//   --ion-color-step-850: #d9d9d9;
//   --ion-color-step-900: #e6e6e6;
//   --ion-color-step-950: #f2f2f2;

//   --ion-toolbar-background: #0d0d0d;

//   --ion-item-background: #1c1c1c;
//   --ion-item-background-activated: #313131;
// }


// /*
//  * Material Design Dark Theme
//  * -------------------------------------------
//  */

// .md {
//   --ion-background-color: #121212;
//   --ion-background-color-rgb: 18,18,18;

//   --ion-text-color: #ffffff;
//   --ion-text-color-rgb: 255,255,255;

//   --ion-border-color: #222222;

//   --ion-color-step-50: #1e1e1e;
//   --ion-color-step-100: #2a2a2a;
//   --ion-color-step-150: #363636;
//   --ion-color-step-200: #414141;
//   --ion-color-step-250: #4d4d4d;
//   --ion-color-step-300: #595959;
//   --ion-color-step-350: #656565;
//   --ion-color-step-400: #717171;
//   --ion-color-step-450: #7d7d7d;
//   --ion-color-step-500: #898989;
//   --ion-color-step-550: #949494;
//   --ion-color-step-600: #a0a0a0;
//   --ion-color-step-650: #acacac;
//   --ion-color-step-700: #b8b8b8;
//   --ion-color-step-750: #c4c4c4;
//   --ion-color-step-800: #d0d0d0;
//   --ion-color-step-850: #dbdbdb;
//   --ion-color-step-900: #e7e7e7;
//   --ion-color-step-950: #f3f3f3;

//   --ion-item-background: #1A1B1E;
// }
// }